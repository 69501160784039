import React from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  const handleClickFilm = () => {
    window.open("https://goldenculture.cn/");
  };
  const handleClickLinkedIn = () => {
    window.open("https://www.linkedin.com/company/faqile/");
  };

  return (
    <nav>
      <div className="container">
        <div className="nav-columns">
          <div className="nav-column">
            <div
              className="nav-label"
              style={{ fontSize: "1.2rem", color: "#f45900" }}
            >
              梦境人生
            </div>
            <ul className="nav-links">
              <li>
                <NavLink to="/synopsis" exact>
                  Synopsis
                </NavLink>
              </li>
              <li>
                <NavLink to="/dream_yoga" exact>
                  Dream Yoga
                </NavLink>
              </li>
              <li>
                <NavLink to="/statement" exact>
                  Director's Statement
                </NavLink>
              </li>
              <li>
                <NavLink to="/casting" exact>
                  Casting
                </NavLink>
              </li>
              <br />
              <li style={{ color: "#555" }}>
                Copyright 2021. 发奇乐 (Faqile).
              </li>
              <li style={{ color: "#555" }}>All rights reserved.</li>
            </ul>
          </div>
          <div className="nav-column">
            <div className="nav-infos">
              <ul className="nav-info">
                <br />
                <div
                  className="nav-label"
                  style={{ fontSize: "1.2rem", color: "#f45900" }}
                >
                  Contact Us
                </div>
                <li className="nav-info-label" style={{ color: "#555" }}>
                  Film Web / Tel
                </li>
                <li
                  onClick={handleClickFilm}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  <a>goldenculture.cn</a>
                </li>
                <li>+86 21 6219 0662</li>
              </ul>
              <ul className="nav-info">
                <br />
                <li className="nav-info-label" style={{ color: "#555" }}>
                  Address
                </li>
                <li>No. 8 Xing Yi Road,</li>
                <li>47F, Shanghai,</li>
                <li>China</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
