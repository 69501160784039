import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { gsap } from "gsap";
import "./styles/App.scss";
import Header from "./components/header";
import Navigation from "./components/navigation";

import Synopsis from "./pages/synopsis";
import DreamYoga from "./pages/dream_yoga";
import Statement from "./pages/statement";
import Casting from "./pages/casting";
import Trailer1 from "./pages/trailer1";
import Trailer2 from "./pages/trailer2";
import Home from "./pages/home";

const routes = [
  { path: "/", name: "Home", Component: Home },
  { path: "/synopsis", name: "synopsis", Component: Synopsis },
  { path: "/dream_yoga", name: "dream_yoga", Component: DreamYoga },
  { path: "/statement", name: "statement", Component: Statement },
  { path: "/casting", name: "casting", Component: Casting },
  { path: "/trailer1", name: "trailer1", Component: Trailer1 },
  { path: "/trailer2", name: "trailer2", Component: Trailer2 },
];

function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

function App() {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    // prevents flashing
    gsap.to("body", 0, { css: { visibility: "visible" } });
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });
  return (
    <>
      <Header dimensions={dimensions} />
      <div className="App">
        {routes.map(({ path, Component }) => (
          <Route key={path} exact path={path}>
            <Component dimensions={dimensions} />
          </Route>
        ))}
      </div>
      <Navigation />
    </>
  );
}

export default App;
