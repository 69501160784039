import React from "react";
import "../styles/components/trailer.scss";
import trailerVideo2 from "../assets/dream_2.mp4";
import dreamPoster2 from "../assets/dream_2.jpg";

export default function Trailer1() {
  return (
    <div className="trailer">
      <div className="embed-container">
        <video muted autoPlay controls poster={dreamPoster2}>
          <source src={trailerVideo2} />
        </video>
      </div>
    </div>
  );
}
