import React from "react";

export default function Casting() {
  return (
    <div className="page contents casting">
      <div className="container">
        <div className="row">
          <div className="content">
            <div className="line">
              <h1>演员阵容</h1>
            </div>
            <div className="line">
              <span>赵文瑄 饰演 李士豪</span>
            </div>
            <div className="line">
              <span>王琳 饰演 朱丽瑛</span>
            </div>
            <div className="line">
              <span>王佳佳 饰演 李水晶</span>
            </div>
            <div className="line">
              <span>张开泰 饰演 年轻李士豪</span>
            </div>
            <div className="line">
              <span>李君婕 饰演 年轻朱丽瑛</span>
            </div>
            <div className="line">
              <span>刘恺威 饰演 韩先权</span>
            </div>
            <div className="line">
              <span>元华 饰演 徐老师</span>
            </div>
            <div className="line">
              <h1>Casting</h1>
            </div>
            <div className="line">
              <span>Winston CHAO as Shihao LI</span>
            </div>
            <div className="line">
              <span>Lin WANG as Liying ZHU</span>
            </div>
            <div className="line">
              <span>Jiajia WANG as Crystal LI</span>
            </div>
            <div className="line">
              <span>Kaitai ZHANG as Shihao LI (young)</span>
            </div>
            <div className="line">
              <span>Junjie LI as Liying ZHU (young)</span>
            </div>
            <div className="line">
              <span>Hawick LAU as Xianquan HAN</span>
            </div>
            <div className="line">
              <span>Wah YUEN as Mr. Xu</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
