import React from "react";
import { ReactComponent as RightArrow } from "../assets/arrow-right.svg";

const Banner = () => {
  const handleClickMaoyan = () => {
    window.open("https://m.maoyan.com/asgard/movie/1438074");
  };

  return (
    <section className="main">
      <div className="container">
        <div className="row">
          <h2>
            <div className="line">
              <span>When love is gone,</span>
            </div>
            <div className="line">
              <span>We still have our memories</span>
            </div>
          </h2>
          <div className="btn-row">
            <a onClick={handleClickMaoyan}>
              在猫眼想看
              <RightArrow />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
