import React from "react";

export default function Synopsis() {
  return (
    <div className="page contents synopsis">
      <div className="container">
        <div className="row">
          <div className="content">
            <div className="line">
              <h1>故事梗概</h1>
            </div>
            <div className="line">
              <span>
                “梦瑜伽”是一种古老又神秘的功夫。李士豪和女儿水晶都是“梦瑜伽”的传人，他们可以在梦中生活，甚至可以通过梦境来改变人生。李士豪在生命垂危之际将自己的公司送给了初恋情人朱丽瑛，水晶对此百思不解。她利用“梦瑜伽”游弋在梦境和记忆碎片中，因为只有找到当年的真相，她才能得知自己是谁，从何而来？
              </span>
            </div>

            <div className="line">
              <h1>Synopsis</h1>
            </div>
            <div className="line">
              <span>
                People come and go in life, but they never leave your dreams. Once they are in your subconscious, they are immortal. “I dreamed a dream” features the protagonist Crystal who travels into the dreams of those related to her unconscious father, Shihao Li, to uncover the truth of an unspoken memory in the 1970s. Crystal’s journey is full of twists and turns. Each dream she goes into tells a different story. And so, a Rashomon style search of the lost memories begins...
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
