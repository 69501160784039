import React from "react";
import "../styles/components/trailer.scss";
import trailerVideo1 from "../assets/dream_1.mp4";
import dreamPoster1 from "../assets/dream_1.jpg";

export default function Trailer1() {
  return (
    <div className="trailer">
      <div className="embed-container">
        <video muted autoPlay controls poster={dreamPoster1}>
          <source src={trailerVideo1} />
        </video>
      </div>
    </div>
  );
}
