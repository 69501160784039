import React from "react";
import pic1 from "../assets/dream_1.jpg";
import pic2 from "../assets/dream_2.jpg";
import { NavLink } from "react-router-dom";

const caseStudies = [
  {
    id: 1,
    subtitle: "Trailer 1",
    title: "Love is to sacrifice for those you love.",
    img: pic1,
    link: "/trailer1",
  },
  {
    id: 2,
    subtitle: "Trailer 2",
    title: "Love is about controlling one’s desires.",
    img: pic2,
    link: "/trailer2",
  },
];

const Cases = () => {
  return (
    <section className="cases">
      <div className="container-fluid">
        <div className="row">
          {caseStudies.map((caseItem) => (
            <NavLink to={caseItem.link} exact>
              <div className="case" key={caseItem.id}>
                <div className="case-details">
                  <span>{caseItem.subtitle}</span>
                  <h2>{caseItem.title}</h2>
                </div>
                <div className="case-image">
                  <img src={caseItem.img} alt={caseItem.title} />
                </div>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Cases;
