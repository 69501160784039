import React from "react";

export default function DreamYoga() {
  return (
    <div className="page contents dream_yoga">
      <div className="container">
        <div className="row">
          <div className="content">
            <div className="line">
              <h1>梦瑜伽</h1>
            </div>
            <div className="line">
              <span>
                此片的编剧和导演受“梦瑜伽”这门古老功夫启发，利用人的梦境和意识的非线性特点，在电影中构造了一个梦瑜伽的世界。记忆是一个人存活过的证据，但随着时间的流逝，人的记忆也会褪色。这时也许只有“梦瑜伽”才能拯救我们的灵魂。梦瑜伽是古老的冥想术，在中国有几千年的历史。
              </span>
            </div>

            <div className="line">
              <h1>Dream Yoga</h1>
            </div>
            <div className="line">
              <span>
                The writer and director of the film is deeply influenced by a mysterious style of Kungfu known as the Dream Yoga. By using the non-linear nature of human dream and consciousness, he constructed the Dream Yoga world. Dream Yoga is an ancient style of meditation in China that has over thousands of years of history.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
