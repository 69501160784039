import React from "react";

export default function Statement() {
  return (
    <div className="page contents director">
      <div className="container">
        <div className="row">
          <div className="content">
            <div className="line">
              <h1>导演阐述 (段祺华)</h1>
            </div>
            <div className="line">
              <span>
                这是一部有关意识、感知和梦境的悬疑爱情片。影片采用了后现代主义的电影结构，通过现实与梦境的空间交替，以及非线性和线性交叉的叙事方式，形成了一种新颖的电影叙事结构。有位大师曾经说过:人因为有记忆，才区别于动物。动物的一生就是一连串的此刻，是点状的，而人的一生是一连串的过去，是线状的。记忆是一个人存活过的证据。
              </span>
            </div>

            <div className="line">
              <h1>Qihua Duan’s Statement</h1>
            </div>
            <div className="line">
              <span>
                This is a romantic mystery film about sensation, perception and dreams. A famous Chinese sociologist and anthropologist once said, “Human beings distinguish themselves from ordinary animals because they have memories. The lives of ordinary animals consist of a series of moments, appearing in the shape of dots. The lives of humans, on the other hand, consist of numerous memories, appearing in the shape of a line.” Memories serve as the proof of one’s existence.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
